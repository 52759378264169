<template>
  <b-container class="mt-3">
    <b-tabs pills>
      <b-tab title="Education History" v-if="!['services'].includes(user_type)">
        <b-card class="mb-3">
          <b-card-body>
            <div v-if="studentData.student_qualification.length === 0">
              No data available
            </div>
            <div>
              <div
                v-for="(education, index) in studentData.student_qualification"
                :key="index"
              >
                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-group label="Name">
                      <b-form-input
                        readonly
                        :value="education.stq_name || '-'"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Course">
                      <b-form-input
                        readonly
                        :value="education.stq_course"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="College">
                      <b-form-input
                        readonly
                        :value="education.stq_college"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="College Address">
                      <b-form-input
                        readonly
                        :value="education.stq_college_address"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Contact">
                      <b-form-input
                        readonly
                        :value="education.stq_contact"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Awarding Board">
                      <b-form-input
                        readonly
                        :value="education.stq_awarding_board"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Country Institution">
                      <b-form-input
                        readonly
                        :value="education.stq_country_instituition"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Date From">
                      <b-form-input
                        readonly
                        :value="
                          education.stq_date_from
                            ? formatDate(education.stq_date_from)
                            : '-'
                        "
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Date To">
                      <b-form-input
                        readonly
                        :value="
                          education.stq_date_to
                            ? formatDate(education.stq_date_to)
                            : '-'
                        "
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Grades">
                      <b-form-input
                        readonly
                        :value="education.stq_grades"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <hr />
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-tab>

      <b-tab title="Gaps" v-if="!['services'].includes(user_type)">
        <b-card class="mb-3">
          <b-card-body>
            <div v-if="studentData.student_gaps.length === 0">
              No data available
            </div>
            <div>
              <div
                v-for="(gap, index) in studentData.student_gaps"
                :key="index"
              >
                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-group label="Period From">
                      <b-form-input
                        readonly
                        :value="
                          gap.stg_period_from
                            ? formatDate(gap.stg_period_from)
                            : '-'
                        "
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Period To">
                      <b-form-input
                        readonly
                        :value="
                          gap.stg_period_to
                            ? formatDate(gap.stg_period_to)
                            : '-'
                        "
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Reason">
                      <b-form-input
                        readonly
                        :value="gap.stg_reason"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Backlogs">
                      <b-form-input
                        readonly
                        :value="gap.stg_backlogs"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-tab>

      <b-tab title="Certifications" v-if="!['services'].includes(user_type)">
        <b-card class="mb-3">
          <b-card-body>
            <div v-if="studentData.student_certifications.length === 0">
              No data available
            </div>
            <div>
              <div
                v-for="(
                  certification, index
                ) in studentData.student_certifications"
                :key="index"
              >
                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-group label="Name">
                      <b-form-input
                        readonly
                        :value="certification.stc_name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Overall">
                      <b-form-input
                        readonly
                        :value="certification.stc_overall"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Speaking">
                      <b-form-input
                        readonly
                        :value="certification.stc_speaking"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Reading">
                      <b-form-input
                        readonly
                        :value="certification.stc_reading"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Listening">
                      <b-form-input
                        readonly
                        :value="certification.stc_listening"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Writing">
                      <b-form-input
                        readonly
                        :value="certification.stc_writing"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Date">
                      <b-form-input
                        readonly
                        :value="
                          certification.stc_date
                            ? formatDate(certification.stc_date)
                            : '-'
                        "
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-tab>

      <b-tab title="Work History" v-if="!['services'].includes(user_type)">
        <b-card class="mb-3">
          <b-card-body>
            <div v-if="studentData.student_work_history.length === 0">
              No data available
            </div>
            <div
              v-for="(work, index) in studentData.student_work_history"
              :key="index"
            >
              <b-row>
                <b-col cols="12" md="4">
                  <b-form-group label="Name">
                    <b-form-input
                      readonly
                      :value="work.swh_name"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Date From">
                    <b-form-input
                      readonly
                      :value="
                        work.swh_date_from
                          ? formatDate(work.swh_date_from)
                          : '-'
                      "
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Date To">
                    <b-form-input
                      readonly
                      :value="
                        work.swh_date_to ? formatDate(work.swh_date_to) : '-'
                      "
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Organisation">
                    <b-form-input
                      readonly
                      :value="work.swh_organisation"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Organisation Address">
                    <b-form-input
                      readonly
                      :value="work.swh_org_address"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Position">
                    <b-form-input
                      readonly
                      :value="work.swh_position"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Employer Name">
                    <b-form-input
                      readonly
                      :value="work.swh_employer_name"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Employer Family Name">
                    <b-form-input
                      readonly
                      :value="work.swh_employer_family_name"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Employer Work Contact">
                    <b-form-input
                      readonly
                      :value="work.swh_employer_work_contact"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Employer Contact">
                    <b-form-input
                      readonly
                      :value="work.swh_employer_contact"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Employer Email">
                    <b-form-input
                      readonly
                      :value="work.swh_employer_email"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr />
            </div>
          </b-card-body>
        </b-card>
      </b-tab>

      <b-tab title="Travel History" v-if="!['services'].includes(user_type)">
        <b-card class="mb-3">
          <b-card-body>
            <div v-if="studentData.student_travel_history.length === 0">
              No data available
            </div>
            <div>
              <div
                v-for="(travel, index) in studentData.student_travel_history"
                :key="index"
              >
                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-group label="Country">
                      <b-form-input
                        readonly
                        :value="travel.sth_country"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="From">
                      <b-form-input
                        readonly
                        :value="
                          travel.sth_from ? formatDate(travel.sth_from) : '-'
                        "
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="To">
                      <b-form-input
                        readonly
                        :value="travel.sth_to ? formatDate(travel.sth_to) : '-'"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Purpose">
                      <b-form-input
                        readonly
                        :value="travel.sth_purpose"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-tab>

      <b-tab title="Visa Rejections" v-if="!['services'].includes(user_type)">
        <b-card class="mb-3">
          <b-card-body>
            <div v-if="studentData.student_visa_rejections.length === 0">
              No data available
            </div>
            <div>
              <div
                v-for="(
                  rejection, index
                ) in studentData.student_visa_rejections"
                :key="index"
              >
                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-group label="Country">
                      <b-form-input
                        readonly
                        :value="rejection.svr_country"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Type">
                      <b-form-input
                        readonly
                        :value="rejection.svr_type"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Reason">
                      <b-form-input
                        readonly
                        :value="rejection.svr_reason"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-tab>

      <b-tab title="Family Details">
        <b-card class="mb-3">
          <b-card-body>
            <div v-if="studentData.student_family_details.length === 0">
              No data available
            </div>
            <div>
              <div
                v-for="(family, index) in studentData.student_family_details"
                :key="index"
              >
                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-group label="Name">
                      <b-form-input
                        readonly
                        :value="family.stf_name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Type">
                      <b-form-input
                        readonly
                        :value="family.sfd_type"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Current Country">
                      <b-form-input
                        readonly
                        :value="family.sfd_current_country"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Date of Birth">
                      <b-form-input
                        readonly
                        :value="
                          family.sfd_dob ? formatDate(family.sfd_dob) : '-'
                        "
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Age">
                      <b-form-input
                        readonly
                        :value="family.sfd_age"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Gender">
                      <b-form-input
                        readonly
                        :value="family.sfd_gender"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Marital Status">
                      <b-form-input
                        readonly
                        :value="family.sfd_marital_status"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Email">
                      <b-form-input
                        readonly
                        :value="family.sfd_email"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Contact Number">
                      <b-form-input
                        readonly
                        :value="family.sfd_contact_number"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Employment Type">
                      <b-form-input
                        readonly
                        :value="family.sfd_employment_type"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="Company Name & Address">
                      <b-form-input
                        readonly
                        :value="family.sfd_company_name_address"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group label="ITR Available">
                      <b-form-input
                        readonly
                        :value="family.sfd_itr_available"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import {
  BContainer,
  BFormGroup,
  BButton,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BTabs,
  BTab,
  BFormInput,
} from "bootstrap-vue";
import CommonServices from "@/apiServices/CommonServices";
import moment from "moment";
import store from "@/store";
export default {
  components: {
    BContainer,
    BFormGroup,
    BButton,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BTabs,
    BTab,
    BFormInput,
  },
  data() {
    return {
      studentData: {
        student_qualification: [],
        student_gaps: [],
        student_certifications: [],
        student_work_history: [],
        student_travel_history: [],
        student_visa_rejections: [],
        student_family_details: [],
      },
    };
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"].user_type) {
        let type = store.getters["user/getUserDetails"].user_type;
        return type;
      } else {
        return null;
      }
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD MMM YYYY");
    },
    async fetchStudentData(student_user_id) {
      try {
        const response = await CommonServices.getAIS(student_user_id);
        if (response.data.status) {
          this.studentData = response.data.data;
        }
      } catch (error) {
        console.log("Error fetching student data", error);
      }
    },
    moment,
  },
  beforeMount() {
    const { student_user_id } = this.$route.params;
    if (student_user_id) {
      this.fetchStudentData(student_user_id);
    }
  },
};
</script>
