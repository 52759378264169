<template>
  <b-card>
    <b-table hover :items="services" :fields="fields">
      <template #cell(show)="row">
        <feather-icon
          icon="PlusCircleIcon"
          size="20"
          v-if="
            !row.detailsShowing &&
            row.item.features &&
            row.item.features.length > 1
          "
          @click="row.toggleDetails"
        />
        <feather-icon
          icon="MinusCircleIcon"
          size="20"
          v-if="row.detailsShowing"
          @click="row.toggleDetails"
        />
      </template>
      <template #cell(final_delivery)="row">
        <span v-if="row.item.final_delivery">
          {{ moment(row.item.final_delivery).format("DD MMM, YYYY HH:mm:ss") }}
        </span>
        <flat-pickr
          v-model="row.item.final_delivery"
          v-else-if="['services'].includes(user_type)"
          class="form-control"
          placeholder="Select Date"
          @input="onSearch(row.item.plan_id)"
          :config="{
            dateFormat: 'Y-m-d H:i',
            enableTime: true,
            time_24hr: true,
            altInput: true,
            altFormat: 'd-m-Y H:i',
            allowInput: true,
            noCalendar: false,
          }"
        />
        <span v-else> - </span>
      </template>

      <template #row-details="row">
        <div
          v-if="row.item.plan_id === 6 && row.item.content.length"
          class="pt-3 pb-5"
        >
          <h4 class="my-1 ml-1 p-0" style="min-width: 500px">Content</h4>
          <div v-for="(c, index) in row.item.content" :key="'c_' + index">
            <div style="width: 270px">
              <embed
                :src="generatePdfBlobUrl(c.sat_report_link)"
                width="100%"
                height="200px"
                type="application/pdf"
              />
              <br />
              <div class="d-flex justify-content-between">
                <span>
                  <a
                    target="_blank"
                    :href="generatePdfBlobUrl(c.sat_report_link)"
                    >open</a
                  >
                </span>
                <span>
                  <a
                    download="sat_report"
                    :href="generatePdfBlobUrl(c.sat_report_link)"
                    >download</a
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
        <b-table hover :items="row.item.features" :fields="featureFields">
          <template #cell(feature_name)="data">
            <span class="font-weight-bold">{{ data.item.feature_name }}</span>
          </template>
          <template #cell(progress)="data">
            <div class="d-flex">
              <b-form-checkbox
                :checked="isFeatureCompleted(data.item.feature_id)"
                :disabled="isFeatureCompleted(data.item.feature_id)"
                v-if="['services'].includes(user_type)"
                @change="
                  (val) => {
                    if (val) {
                      openConfirmationModal(
                        row.item.service_id,
                        row.item.plan_id,
                        data.item.feature_id
                      );
                    }
                  }
                "
              />
              <b-badge
                class="ml-1"
                :variant="
                  isFeatureCompleted(data.item.feature_id)
                    ? 'success'
                    : 'warning'
                "
                >{{
                  isFeatureCompleted(data.item.feature_id)
                    ? "Complete"
                    : "Pending"
                }}</b-badge
              >
            </div>
          </template>
          <template #cell(completion_date)="data">
            <div v-if="getCompletedFeatureDate(data.item.feature_id)">
              {{
                moment(getCompletedFeatureDate(data.item.feature_id)).format(
                  "DD MMM, YYYY HH:mm:ss"
                )
              }}
            </div>
            <div v-else>-</div>
          </template>
          <template #cell(documents)="data">
            <div class="d-flex justify-content-start">
              <feather-icon
                icon="FilePlusIcon"
                size="20"
                class="cursor-pointer"
                stroke="#333"
                @click="openModal(data.item.feature_id, data.item.plan_id)"
                v-if="
                  data.item.is_document_required == 'Y' &&
                  !data.item.sfd_file_path &&
                  ['services'].includes(user_type)
                "
              />
              <div v-else-if="data.item.sfd_file_path">
                <a :href="FILESURL + data.item.sfd_file_path" target="_blank">
                  <feather-icon
                    icon="EyeIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                </a>
              </div>
              <div v-if="data.item.feature_id === 99">
                <feather-icon
                  icon="EyeIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="getSOPAnswersByStudentId"
                />
              </div>
            </div>
          </template>
          <template #cell(remarks)="data">
            <div class="d-flex justify-content-start">
              <b-popover
                v-if="data.item.sfd_remark && data.item.sfd_remark.length > 0"
                :content="data.item.sfd_remark"
                triggers="hover"
                placement="top"
                target="remark"
              >
                <h6>{{ data.item.sfd_remark }}</h6>
              </b-popover>
              <!-- <h6 v-else-if="data.item.sfd_remark" id="remark">{{ data.item.sfd_remark }}</h6> -->
              <span v-if="data.item.sfd_remark">
                <feather-icon
                  icon="InfoIcon"
                  size="20"
                  class="cursor-pointer"
                  id="remark"
                />
              </span>
              <h4 v-else>
                <feather-icon
                  icon="MinusIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="getFeatureDocById(data.item.sfd_id)"
                />
              </h4>
            </div>
          </template>
          <template #cell(action)="data">
            <div class="d-flex justify-content-start">
              <feather-icon
                icon="EditIcon"
                size="20"
                class="cursor-pointer"
                @click="getFeatureDocById(data.item.sfd_id)"
                v-if="data.item.sfd_file_path !== null"
              />
              <feather-icon
                icon="MinusIcon"
                size="20"
                class="cursor-pointer"
                @click="getFeatureDocById(data.item.sfd_id)"
                v-else
              />
            </div>
          </template>
        </b-table>

        <b-card title="Accommodation Favourites" class="mt-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
              lg="4"
              v-for="listing in accomodation_favourites"
              :key="listing.id"
            >
              <b-card
                :img-src="FILESURL + listing.aci_url"
                img-alt="listing.title"
                img-top
                style="box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important"
              >
                <div>
                  <div class="d-flex justify-content-start">
                    <b-card-title class="font-weight-bold">{{
                      listing.acc_name
                    }}</b-card-title>
                  </div>
                  <b-card-text>
                    <div class="mt-1">
                      <div>
                        <p class="mb-1">
                          <feather-icon
                            icon="MapPinIcon"
                            stroke="#000"
                            style="margin-right: 8px"
                            size="20"
                          />{{ listing.acc_address }},<span
                            style="font-weight: 600"
                            >{{ listing.city_name }}</span
                          >
                        </p>
                      </div>
                      <div>
                        <feather-icon
                          icon="BoxIcon"
                          stroke="#000"
                          style="margin-right: 8px"
                          size="20"
                        />
                        {{ listing.acf_names }}
                      </div>
                    </div>
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        mt-2
                      "
                    >
                      <div class="d-flex align-items-center">
                        <h4 class="font-weight-bold m-0">
                          ${{ listing.acc_price_per_month }}
                        </h4>
                        <span>/month</span>
                      </div>
                    </div>
                  </b-card-text>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <div v-if="accomodation_favourites.length === 0">
            <h5 class="text-primary ml-2">No Shortlisted Accommodation</h5>
          </div>
        </b-card>

        <b-card title="Recommended Accommodation " class="mt-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
              lg="4"
              v-for="listing in recommendations"
              :key="listing.id"
            >
              <b-card
                :img-src="FILESURL + listing.aci_url"
                img-alt="listing.title"
                img-top
                style="box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important"
              >
                <div>
                  <div class="d-flex justify-content-start">
                    <b-card-title class="font-weight-bold">{{
                      listing.acc_name
                    }}</b-card-title>
                  </div>
                  <b-card-text>
                    <div class="mt-1">
                      <div>
                        <p class="mb-1">
                          <feather-icon
                            icon="MapPinIcon"
                            stroke="#000"
                            style="margin-right: 8px"
                            size="20"
                          />{{ listing.acc_address }},<span
                            style="font-weight: 600"
                            >{{ listing.city_name }}</span
                          >
                        </p>
                      </div>
                      <div>
                        <feather-icon
                          icon="BoxIcon"
                          stroke="#000"
                          style="margin-right: 8px"
                          size="20"
                        />
                        {{ listing.acf_names }}
                      </div>
                    </div>
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        mt-2
                      "
                    >
                      <div class="d-flex align-items-center">
                        <h4 class="font-weight-bold m-0">
                          ${{ listing.acc_price_per_month }}
                        </h4>
                        <span>/month</span>
                      </div>
                      <div>
                        <feather-icon
                          icon="Trash2Icon"
                          stroke="#f33"
                          style="cursor: pointer; margin-right: 8px"
                          size="20"
                          @click="deleteRecommendation(listing.acc_id)"
                        />
                      </div>
                    </div>
                  </b-card-text>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <div v-if="recommendations.length === 0">
            <h5 class="text-primary ml-2">No Recommended Accommodation</h5>
          </div>
        </b-card>

        <b-modal
          title="Add Document"
          centered
          hide-footer
          id="add-document-modal"
        >
          <b-form>
            <div>
              <b-form-input
                id="doc-name"
                v-model="upload_doc_data.sfd_name"
                placeholder="Enter document name"
                required
              />
            </div>

            <div class="my-2">
              <b-form-file
                id="doc-file"
                v-model="documentFile"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                required
              />
            </div>

            <div class="mb-2">
              <b-form-input
                id="doc-remark"
                v-model="upload_doc_data.sfd_remark"
                placeholder="Enter Remarks"
                required
              />
            </div>

            <div class="d-flex justify-content-end">
              <b-button variant="primary" @click="documentUpload">
                Submit
              </b-button>
            </div>
          </b-form>
        </b-modal>

        <b-modal
          id="modal-questions-view-st"
          title="SOP Questions"
          size="xl"
          no-close-on-backdrop
          no-close-on-esc
          hide-footer
          @hidden="resetAnswerPage"
        >
          <div
            v-for="(item, index) in paginatedQuestionsWithAnswers"
            :key="item.sq_id"
            class="mb-3"
          >
           <b-card class="p-2 shadow-lg">
             <!-- Main question -->
             <label
              :for="'view-question-' + index"
              class="d-block font-weight-bold"
              style="font-size: larger;"
            >
              {{ (answer_currentPage ) * perPage + index + 1 }}.
              {{ item.sq_question }}
            </label>
            <b-form-textarea
              :id="'view-question-' + index"
              v-model="answers[`main_${item.sq_id}`]"
              :placeholder="item.sqa_answer || 'Enter your answer'"
              rows="2"
              class="mb-2"
            ></b-form-textarea>

            <!-- Subquestions -->
            <div
              v-if="item.sub_questions && item.sub_questions.length"
              class="pl-3"
            >
              <div
                v-for="(subq, subIndex) in item.sub_questions"
                :key="subq.ssq_id"
                class="mb-2"
              >
                <label
                  :for="`view-sub-${subq.ssq_id}`"
                  class="font-weight-bold"
                  style="font-size: medium;"
                >
                  {{ String.fromCharCode(97 + subIndex) }})
                  {{ subq.ssq_question }}
                </label>
                <b-form-textarea
                  :id="`view-sub-${subq.ssq_id}`"
                  v-model="answers[`sub_${subq.ssq_id}`]"
                  :placeholder="subq.sqa_sub_answer || 'Enter your answer'"
                  rows="2"
                  class="mb-1"
                ></b-form-textarea>
              </div>
            </div>


          </b-card>
        </div>
        <!-- Add the download button -->
        <div class="d-flex justify-content-end mt-3">
          <b-button variant="primary" @click="downloadFile">
            Download Questions and Answers
          </b-button>
        </div>

          <!-- Pagination Controls -->
          <div class="d-flex justify-content-between mt-2 align-items-center">
            <b-button
              variant="outline-primary"
              @click="prevAnswerPage"
              size="sm"
              :disabled="answer_currentPage === 0"
            >
              Previous
            </b-button>
            <span
              >Page {{ answer_currentPage + 1 }} /
              {{ totalPagesWithAnswers }}</span
            >
            <b-button v-if="answer_currentPage === totalPagesWithAnswers - 1" variant="success" @click="submitAnswers">
              Submit
            </b-button>
            <b-button
              variant="outline-primary"
              @click="nextAnswerPage"
              size="sm"
              v-else
            >
              Next
            </b-button>

            
          </div>
        </b-modal>

        <b-modal
          id="confirmation-modal"
          title="Confirmation"
          centered
          hide-footer
          no-close-on-backdrop
          no-close-on-esc
          size="sm"
        >
          <div class="p-1">
            <h5>Are you sure you want to mark this as completed ?</h5>
            <div class="d-flex justify-content-end mt-3">
              <b-button
                variant="primary"
                @click="markFeatureCompleted"
                class="ml-2"
              >
                Confirm
              </b-button>
            </div>
          </div>
        </b-modal>
      </template>
      <template #cell(buy_date)="row">
        {{
          moment(row.item.transaction_datetime).format("DD MMM, YYYY - HH:mm")
        }}
      </template>
    </b-table>
  </b-card>
</template>
<script>
import BCardCode from "@core/components/b-card-code";
import {
  BCarousel,
  BCarouselSlide,
  BButtonGroup,
  BButton,
  BLink,
  BCardText,
  BTabs,
  BTab,
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
  BCollapse,
  BListGroup,
  BListGroupItem,
  BFormCheckbox,
  BCol,
  BRow,
  BTable,
  BForm,
  BFormInput,
  BformGroup,
  BFormFile,
  BFormTextarea,
  BCardTitle,
  BPopover,
  BPagination,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import CommonServices from "@/apiServices/CommonServices";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import { BASEURL, FILESURL } from "@/config";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";
import { TokenService } from "@/apiServices/storageService";
import axios from "axios";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  components: {
    BCardCode,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BCarousel,
    BCarouselSlide,
    BButtonGroup,
    BButton,
    BCardText,
    BTabs,
    BLink,
    BTab,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BRow,
    BCol,
    BTable,
    BBadge,
    flatPickr,
    BForm,
    BFormInput,
    BformGroup,
    BFormFile,
    BFormTextarea,
    BCardTitle,
    BPopover,
    BPagination,
  },
  directives: {
    Ripple,
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"]?.user_type) {
        return store.getters["user/getUserDetails"].user_type;
      } else {
        return null;
      }
    },
    totalPagesWithAnswers() {
      return Math.ceil(this.question_with_answers.length / this.perPage);
    },
    paginatedQuestionsWithAnswers() {
      const start = this.answer_currentPage * this.perPage;
      return this.question_with_answers.slice(start, start + this.perPage);
    },
  },

  data() {
    return {
      services: [],
      student_user_id: null,
      completed_features: [],
      date: "",
      fields: [
        "show",
        "service_name",
        "plan_name",
        "amount",
        "buy_date",
        "final_delivery",
      ],
      featureFields: [
        { key: "feature_name", label: "Features", sortable: true },
        { key: "progress", label: "Progress", sortable: false },
        { key: "completion_date", label: "Completion Date", sortable: true },
        // { key: "documents", label: "Documents", sortable: false },
        // { key: "remarks", label: "Remarks", sortable: false },
        // { key: "action", label: "Action", sortable: false },
      ],
      debounce: null,
      FILESURL,
      BASEURL,
      documentFile: null,
      documentName: "",
      upload_doc_data: {
        plan_id: null,
        feature_id: null,
        sfd_name: null,
        sfd_file_path: null,
        sfd_id: null,
        student_user_id: null,
        sfd_remark: null,
      },
      answers: {},
      question_with_answers: [],
      service_id: null,
      plan_id: null,
      feature_id: null,
      accomodation_favourites: [],
      recommendations: [],
      answer_currentPage: 0,
      perPage: 2,
    };
  },
  methods: {
    resetAnswerPage() {
      this.answer_currentPage = 0;
    },
    prevAnswerPage() {
      if (this.answer_currentPage > 0) this.answer_currentPage--;
    },
    nextAnswerPage() {
      if (this.answer_currentPage < this.totalPagesWithAnswers - 1)
        this.answer_currentPage++;
    },
    async submitAnswers() {
      try {
        const formattedAnswers = [];

        // Process main questions and their answers
        this.question_with_answers.forEach((question) => {
          if (this.answers[`main_${question.sq_id}`] !== undefined) {
            formattedAnswers.push({
              sq_id: question.sq_id,
              ssq_id: null,
              sqa_answer: this.answers[`main_${question.sq_id}`],
              sqa_sub_answer: null,
              student_user_id: this.student_user_id,
            });
          }

          // Process subquestions and their answers
          if (question.sub_questions && question.sub_questions.length) {
            question.sub_questions.forEach((subq) => {
              if (this.answers[`sub_${subq.ssq_id}`] !== undefined) {
                formattedAnswers.push({
                  sq_id: question.sq_id,
                  ssq_id: subq.ssq_id,
                  sqa_answer: null,
                  sqa_sub_answer: this.answers[`sub_${subq.ssq_id}`],
                  student_user_id: this.student_user_id,
                });
              }
            });
          }
        });
        const res = await CommonServices.saveSOPAnswers({
          answers: formattedAnswers,
          st_user_id: this.student_user_id,
        });

        if (res.data.status) {
          this.$bvModal.hide("modal-questions-view-st");
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Answers saved successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      } catch (error) {
        console.error("Error in submitting answers", error);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Error saving answers",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    generateFileContent() {
    let content = '';
    this.question_with_answers.forEach((question, index) => {
      content += `${index + 1}. ${question.sq_question}\n`;
      content += `Answer: ${this.answers[`main_${question.sq_id}`] || 'N/A'}\n\n`;

      if (question.sub_questions && question.sub_questions.length) {
        question.sub_questions.forEach((subq, subIndex) => {
          content += `  ${String.fromCharCode(97 + subIndex)}) ${subq.ssq_question}\n`;
          content += `  Answer: ${this.answers[`sub_${subq.ssq_id}`] || 'N/A'}\n\n`;
        });
      }
    });
    return content;
  },
  downloadFile() {
    const content = this.generateFileContent();
    const doc = new jsPDF();
    doc.setFontSize(12);
    const lines = doc.splitTextToSize(content, 180); // 180 is the max width of the text
    doc.text(lines, 10, 10);
    doc.save('questions_and_answers.pdf');
    },

    async getSOPAnswersByStudentId() {
      try {
        const res = await CommonServices.getSOPAnswersByStudentId({
          st_user_id: this.student_user_id,
        });
        if (res.data.status) {
          this.question_with_answers = res.data.data;

          // Pre-fill answers object with existing answers
          this.answers = {};
          this.question_with_answers.forEach((question) => {
            if (question.sqa_answer !== null) {
              this.answers[`main_${question.sq_id}`] = question.sqa_answer;
            }
            if (question.sub_questions) {
              question.sub_questions.forEach((subq) => {
                if (subq.sqa_sub_answer !== null) {
                  this.answers[`sub_${subq.ssq_id}`] = subq.sqa_sub_answer;
                }
              });
            }
          });

          this.$bvModal.show("modal-questions-view-st");
        }
      } catch (error) {
        console.error("Error in getting SOP answers", error);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Error loading answers",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },

    openModal(f_id, p_id) {
      this.upload_doc_data.feature_id = f_id;
      this.upload_doc_data.plan_id = p_id;
      this.upload_doc_data.student_user_id = this.student_user_id;
      this.$bvModal.show("add-document-modal");
    },
    generatePdfBlobUrl(binary) {
      const byteCharacters = atob(binary);
      const byteNumbers = Array.from(byteCharacters, (char) =>
        char.charCodeAt(0)
      );
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });
      return URL.createObjectURL(blob);
    },
    moment,
    isFeatureCompleted(feature_id) {
      let student_user_id = this.student_user_id;

      const check = this.completed_features.find(
        (feature) =>
          feature.student_user_id == student_user_id &&
          feature.feature_id == feature_id
      );

      if (check) return true;
      return false;
    },

    getCompletedFeatureDate(feature_id) {
      let student_user_id = this.student_user_id;

      const feature = this.completed_features.find(
        (feature) =>
          feature.student_user_id == student_user_id &&
          feature.feature_id == feature_id
      );

      if (feature) return feature.created_at;
      return null;
    },

    async documentUpload() {
      if (!this.documentFile) {
        this.addDoc();
        return;
      }

      try {
        const AUTH_TOKEN = TokenService.getToken();

        const formData = new FormData();
        formData.append("user_id", this.student_user_id);
        formData.append("sfd_doc", this.documentFile);

        const response = await axios.post(
          `${BASEURL}/common/upload-feature-doc`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              authorization: AUTH_TOKEN,
            },
          }
        );

        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Document Uploaded Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.upload_doc_data.sfd_file_path = response.data.data.file_path;
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Document Upload Failed",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        console.log("Error uploading file ", err);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Server Error",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      } finally {
        this.addDoc();
      }
    },
    async addDoc() {
      try {
        const res = await CommonServices.addFeatureDocument(
          this.upload_doc_data
        );
        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Document Added",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$bvModal.hide("add-document-modal");
          this.getStudentServicesAvailed(this.student_user_id);
          this.reset_upload_doc_data();
        }
      } catch (error) {
        console.error("Error in adding Document ", error);
      }
    },
    reset_upload_doc_data() {
      this.upload_doc_data = {
        plan_id: null,
        feature_id: null,
        sfd_name: null,
        sfd_file_path: null,
        sfd_id: null,
        student_user_id: null,
        sfd_remark: null,
      };
    },

    async getFeatureDocById(sfd_id) {
      try {
        const res = await CommonServices.getFeatureDocById(sfd_id);
        if (res.data.status) {
          const doc = res.data.data[0];
          this.upload_doc_data = doc;
          this.upload_doc_data.student_user_id = doc.user_id;
          this.$bvModal.show("add-document-modal");
        }
      } catch (error) {
        console.error("Error in getting feature doc ", error);
      }
    },

    async getStudentServicesAvailed(student_user_id) {
      try {
        const response = await CommonServices.getStudentServicesAvailed({
          student_user_id,
        });
        if (response.data.status) {
          this.services = response.data.data.map((service) => ({
            ...service,
          }));
        }
      } catch (err) {
        console.log("Error in getting syudent availed services ", err);
      }
    },

    onChange() {
      this.$nextTick(() => {
        this.getStudentServicesAvailed();
      });
    },
    onSearch(plan_id) {
      if (this.debounce) {
        clearTimeout(this.debounce);
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        const row = this.services.find(
          (service) => service.plan_id === plan_id
        );
        if (row) {
          this.saveServiceFinalDelivery(plan_id, row.final_delivery);
        }
      }, 1000);
    },

    async saveServiceFinalDelivery(plan_id, finalDelivery) {
      const payload = {
        student_user_id: this.student_user_id,
        plan_id,
        final_delivery: finalDelivery,
      };

      const res = await CommonServices.saveServiceFinalDelivery(payload);
      if (res.data.status) {
        this.getStudentServicesAvailed(this.student_user_id);
      }
    },

    openConfirmationModal(service_id, plan_id, feature_id) {
      this.$bvModal.show("confirmation-modal");
      this.service_id = service_id;
      this.plan_id = plan_id;
      this.feature_id = feature_id;
    },
    // markAsCompleted(){
    //   this.markFeatureCompleted(this.service_id, this.plan_id, this.feature_id);
    // },
    async markFeatureCompleted() {
      try {
        const response = await CommonServices.markFeatureCompleted({
          student_user_id: this.student_user_id,
          service_id: this.service_id,
          plan_id: this.plan_id,
          feature_id: this.feature_id,
          // unmark,
        });
        if (response.data.status) {
          this.getCompletedFeatures(this.student_user_id);
          this.$bvModal.hide("confirmation-modal");
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Failed to update feature",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      } catch (err) {
        console.log("Error in getting syudent availed services ", err);
      }
    },

    async getCompletedFeatures(student_user_id) {
      try {
        const response = await CommonServices.getCompletedFeatures({
          student_user_id,
        });
        if (response.data.status) {
          this.completed_features = response.data.data;
        }
      } catch (err) {
        console.log("Error in getCompletedFeatures", err);
      }
    },

    async getAccomodationFavouriteByStudentId() {
      try {
        const res = await CommonServices.getAccomodationFavouriteByStudentId({
          student_user_id: this.student_user_id,
        });
        if (res.data.status) {
          this.accomodation_favourites = res.data.data;
        }
      } catch (error) {
        console.error("Error in getAccomodationFavouriteByStudentId", error);
      }
    },
    async getRecommendedAccommodation() {
      try {
        const res = await CommonServices.getRecommendedAccomodationByStudentId({
          student_user_id: this.student_user_id,
        });
        if (res.data.status) {
          this.recommendations = res.data.data;
        }
      } catch (error) {
        console.error("Error in getRecommendedAccommodation", error);
      }
    },
    async deleteRecommendation(acc_id) {
      try {
        const res = await CommonServices.removeRecommendedAccomodation({
          student_user_id: this.student_user_id,
          acc_id,
        });
        if (res.data.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Accommodation removed",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.getRecommendedAccommodation();
        }
      } catch (error) {
        console.error("Error in deleteRecommendation", error);
      }
    },
  },

  beforeMount() {
    const { student_user_id } = this.$route.params;
    if (!isNaN(student_user_id)) {
      this.getStudentServicesAvailed(student_user_id);
      this.getCompletedFeatures(student_user_id);
      this.student_user_id = student_user_id;
    } else {
      console.log("Student User Id is is not a number ", student_user_id);
    }
  },
  mounted() {
    this.getAccomodationFavouriteByStudentId();
    this.getRecommendedAccommodation();
  },
};
</script>

<style></style>
