<template>
  <b-container>
    <b-form>
      <b-row>
        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Student Name:">
            <b-form-input
            v-if="isOperationsManager"
              disabled="true"
              v-model="form.userName"
            ></b-form-input>
            <p v-else>{{ form.userName }}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Country:">
            <v-select
              v-if="isOperationsManager"
              v-model="form.country_id"
              :options="countries"
              :reduce="(item) => item.country_id"
              label="country_name"
            ></v-select>
            <p v-else>{{ getCountryName(form.country_id) || "-" }}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Finalized College:">
            <v-select
              v-if="isOperationsManager"
              v-model="form.finalizedCollege"
              :options="universities"
              :reduce="(item) => item.university_id"
              label="university_name"
            ></v-select>
            <p v-else>{{ getUniversityName(form.finalizedCollege) || "-" }}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Finalized Course:">
            <v-select
              v-if="isOperationsManager"
              v-model="form.course_id"
              :options="courses"
              :reduce="(item) => item.course_id"
              label="course_name"
            ></v-select>
            <p v-else>{{ getCourseName(form.course_id) || "-" }}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Intake Month:">
            <v-select
              v-if="isOperationsManager"
              v-model="form.stp_intake_month"
              :reduce="(item) => item"
              :options="intake_months"
            ></v-select>
            <p v-else>{{ form.stp_intake_month  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Intake Year:">
            <v-select
              v-if="isOperationsManager"
              v-model="form.stp_intake_year"
              :reduce="(item) => item"
              :options="intake_years"
            ></v-select>
            <p v-else>{{ form.stp_intake_year  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Campus Location:">
            <v-select
              v-if="isOperationsManager"
              v-model="form.stp_campus_location"
              :reduce="(item) => item"
              :options="campus_locations"
            ></v-select>
            <p v-else>{{ form.stp_campus_location  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="PSM Date:">
            <b-form-input
              v-if="isOperationsManager"
              v-model="form.stp_psm_date"
              type="date"
            ></b-form-input>
            <p v-else>{{ form.stp_psm_date  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Participated with:">
            <b-form-input
              v-if="isOperationsManager"
              v-model="form.stp_participated_with"
            ></b-form-input>
            <p v-else>{{ form.stp_participated_with  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" style="border-bottom: 1px dotted #B2B6BA;" class="mb-3 mt-2"></b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Counselor:">
            <b-form-input
            v-if="isOperationsManager"
              disabled="true"
              v-model="form.stp_counselor"
            ></b-form-input>
            <p v-else>{{ form.stp_counselor  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="IELTS Exam Date:">
            <b-form-input
              v-if="isOperationsManager"
              v-model="form.stp_ielts_exam_date"
              type="date"
            ></b-form-input>
            <p v-else>{{ form.stp_ielts_exam_date  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Primary Pending Documents:">
            <b-form-input
              v-if="isOperationsManager"
              v-model="form.stp_primary_pending_documents"
            ></b-form-input>
            <p v-else>{{ form.stp_primary_pending_documents  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Primary Pending Docs Recv Deadline:">
            <b-form-input
              v-if="isOperationsManager"
              v-model="form.stp_primary_pending_docs_recv_deadline"
              type="date"
            ></b-form-input>
            <p v-else>{{ form.stp_primary_pending_docs_recv_deadline  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Application Fee Payment Deadline:">
            <b-form-input
              v-if="isOperationsManager"
              v-model="form.stp_application_fee_payment_deadline"
              type="date"
            ></b-form-input>
            <p v-else>{{ form.stp_application_fee_payment_deadline  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group
            label="Previous Visa’s (If any) state Country/ Type/Year:"
          >
            <b-form-input
              v-if="isOperationsManager"
              v-model="form.stp_previous_visas"
            ></b-form-input>
            <p v-else>{{ form.stp_previous_visas  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="12" class="mb-2">
          <b-form-group label="Sponsor Name & Relationship:">
            <b-form-input
              v-if="isOperationsManager"
              v-model="form.stp_sponsor_name_relationship"
            ></b-form-input>
            <p v-else>{{ form.stp_sponsor_name_relationship  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" class="mb-2">
          <b-form-group label="Counselor Recommendations:">
            <b-form-textarea
              v-if="isOperationsManager"
              v-model="form.stp_counselor_recommendations"
            ></b-form-textarea>
            <p v-else>{{ form.stp_counselor_recommendations  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" class="mb-2">
          <b-form-group
            label="Sponsor Details / Remark: (Designation / Income/ Company Name):"
          >
            <b-form-textarea
              v-if="isOperationsManager"
              v-model="form.stp_sponsor_details_remark"
            ></b-form-textarea>
            <p v-else>{{ form.stp_sponsor_details_remark  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" style="border-bottom: 1px dotted #B2B6BA;" class="mb-3 mt-2"></b-col>


        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Sibling Details if any:">
            <b-form-input
              v-if="isOperationsManager"
              v-model="form.stp_sibling_details"
            ></b-form-input>
            <p v-else>{{ form.stp_sibling_details  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Any Relatives in abroad: (Relationship/Visa):">
            <b-form-input
              v-if="isOperationsManager"
              v-model="form.stp_relatives_abroad"
            ></b-form-input>
            <p v-else>{{ form.stp_relatives_abroad  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group
            label="ITR filing Update: (Last 2yrs filed Gross Income amount):"
          >
            <b-form-input
              v-if="isOperationsManager"
              v-model="form.stp_itr_filing_update"
            ></b-form-input>
            <p v-else>{{ form.stp_itr_filing_update  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Funding Type with amount:">
            <b-form-input
              v-if="isOperationsManager"
              v-model="form.stp_funding_type_amount"
            ></b-form-input>
            <p v-else>{{ form.stp_funding_type_amount  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Bank Name/Approx. amount/Source:">
            <b-form-input
              v-if="isOperationsManager"
              v-model="form.stp_bank_name_amount_source"
            ></b-form-input>
            <p v-else>{{ form.stp_bank_name_amount_source  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Savings:">
            <b-form-input
              v-if="isOperationsManager"
              v-model="form.stp_savings"
            ></b-form-input>
            <p v-else>{{ form.stp_savings  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Fixed Deposit:">
            <b-form-input
              v-if="isOperationsManager"
              v-model="form.stp_fixed_deposit"
            ></b-form-input>
            <p v-else>{{ form.stp_fixed_deposit  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Education loan:">
            <b-form-input
              v-if="isOperationsManager"
              v-model="form.stp_education_loan"
            ></b-form-input>
            <p v-else>{{ form.stp_education_loan  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Actual Total Funds Required:">
            <b-form-input
              v-if="isOperationsManager"
              v-model="form.stp_total_funds_required"
            ></b-form-input>
            <p v-else>{{ form.stp_total_funds_required  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4" class="mb-2">
          <b-form-group label="Counselor Recommendations: (for Funds):">
            <b-form-textarea
              v-if="isOperationsManager"
              v-model="form.stp_counselor_recommendations_for_funds"
            ></b-form-textarea>
            <p v-else>{{ form.stp_counselor_recommendations_for_funds  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Overall PSM Remarks of Counselor towards student profile:"
          >
            <b-form-textarea
              v-if="isOperationsManager"
              v-model="form.stp_overall_psm_remarks"
            ></b-form-textarea>
            <p v-else>{{ form.stp_overall_psm_remarks  || "-"}}</p>
          </b-form-group>
        </b-col>

        <b-col cols="12" v-if="isOperationsManager" class="">
          <b-button variant="primary" @click="saveStudentPSM">Submit</b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import CommonServices from "@/apiServices/CommonServices";
import OperationsManagerServices from "@/apiServices/OperationsManagerServices";
import {
  BContainer,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BRow,
  BCol,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import moment from "moment";
export default {
  components: {
    BContainer,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BRow,
    BCol,
    vSelect,
  },
  data() {
    return {
      form: {
        studentName: "",
        country_id: "",
        finalizedCollege: "",
        course_id: "",
        stp_intake_month: "",
        stp_intake_year: "",
        stp_campus_location: "",
        stp_psm_date: "",
        stp_participated_with: "",
        stp_counselor: "",
        stp_ielts_exam_date: "",
        stp_primary_pending_documents: "",
        stp_primary_pending_docs_recv_deadline: "",
        stp_counselor_recommendations: "",
        stp_application_fee_payment_deadline: "",
        stp_previous_visas: "",
        stp_sponsor_name_relationship: "",
        stp_sponsor_details_remark: "",
        stp_sibling_details: "",
        stp_relatives_abroad: "",
        stp_itr_filing_update: "",
        stp_funding_type_amount: "",
        stp_bank_name_amount_source: "",
        stp_savings: "",
        stp_fixed_deposit: "",
        stp_education_loan: "",
        stp_total_funds_required: "",
        stp_counselor_recommendations_for_funds: "",
        stp_overall_psm_remarks: "",
        student_user_id: null,
      },
      studentDetails: null,
      courses: [],
      universities: [],
      countries: [],
      intake_months: [],
      intake_years: [],
      campus_locations: [],
    };
  },
  methods: {
    async saveStudentPSM() {
      try {
        const response = await OperationsManagerServices.saveStudentPSM(
          this.form
        );
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Student PSM Saved",
              icon: "BellIcon",
              variant: "success",
            },
          });
        }
      } catch (error) {
        console.log("error in saveStudentPSM", error);
      }
    },
    async getStudentDetails(student_user_id) {
      try {
        const response = await CommonServices.getUserProfilePSM(
          student_user_id
        );
        if (response.data.status) {
          this.studentDetails = response.data.data;
          this.form.userName = this.studentDetails.user_name;
          this.form.stp_counselor = this.studentDetails.counselor;
          this.courses = [
            { course_id: null, course_name: "Not Decided" },
            ...this.studentDetails.courses,
          ];
          this.countries = [
            { country_id: null, country_name: "Not Decided" },
            ...this.studentDetails.countries,
          ];
          this.universities = [
            { university_id: null, university_name: "Not Decided" },
            ...this.studentDetails.universities,
          ];
          this.intake_months = [
            "Not Decided",
            ...this.studentDetails.intake_months,
          ];
          this.intake_years = [
            "Not Decided",
            ...this.studentDetails.intake_years,
          ];
          this.campus_locations = [
            "Not Decided",
            ...this.studentDetails.campus_locations,
          ];
        }
      } catch (error) {
        console.log("Error in getStudentDetails", error);
      }
    },
    moment,
    async getPSM(student_user_id) {
      try {
        const response = await CommonServices.getPSM(student_user_id);
        if (response.data.status) {
          const data = response.data.data;
          this.form = {
            ...data,
            stp_psm_date: data.stp_psm_date
              ? moment(data.stp_psm_date).format("YYYY-MM-DD")
              : "",
            stp_ielts_exam_date: data.stp_ielts_exam_date
              ? moment(data.stp_ielts_exam_date).format("YYYY-MM-DD")
              : "",
            stp_primary_pending_docs_recv_deadline:
              data.stp_primary_pending_docs_recv_deadline
                ? moment(data.stp_primary_pending_docs_recv_deadline).format(
                    "YYYY-MM-DD"
                  )
                : "",
            stp_application_fee_payment_deadline:
              data.stp_application_fee_payment_deadline
                ? moment(data.stp_application_fee_payment_deadline).format(
                    "YYYY-MM-DD"
                  )
                : "",
          };
        }
      } catch (error) {
        console.log("Error in getPSM", error);
      }
    },
    getCountryName(country_id) {
      const country = this.countries.find(
        (country) => country.country_id === country_id
      );
      return country ? country.country_name : "";
    },
    getUniversityName(university_id) {
      const university = this.universities.find(
        (university) => university.university_id === university_id
      );
      return university ? university.university_name : "";
    },
    getCourseName(course_id) {
      const course = this.courses.find(
        (course) => course.course_id === course_id
      );
      return course ? course.course_name : "";
    },
  },
  computed: {
    isOperationsManager() {
      return this.user_type === "operations_manager";
    },
    user_type() {
      if (store.getters["user/getUserDetails"].user_type) {
        let type = store.getters["user/getUserDetails"].user_type;
        return type;
      } else {
        return null;
      }
    },
  },
  beforeMount() {
    const { student_user_id } = this.$route.params;
    if (student_user_id) {
      this.form.student_user_id = student_user_id;
      this.getStudentDetails(student_user_id);
      this.getPSM(student_user_id);
    }
  },
};
</script>

<style scoped></style>
